import React from 'react';
import Layout from '~components/layout';
import Seo from '~components/seo';
import ForgotPassword from '~containers/authenticate/forgot-password';

const ForgotPasswordPage = () => {
  return (
    <Layout>
      <Seo title='Forgot Password' />
      <ForgotPassword />
    </Layout>
  );
};

export default ForgotPasswordPage;
