import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Button, Form, Input, Breadcrumb, Row, Col, message} from 'antd';
import {forgotPassword} from '~actions/user';
import Loading from '~components/page-loader';
import ChevronRight from '~components/icons/chevron-right';
import {Link} from 'gatsby';

import './styles.scss';

const ForgotPassword = ({forgot}) => {
  const [loading, setLoading] = useState(false);
  const [sentMail, setSentMail] = useState(false);

  const handleSendMail = async ({email}) => {
    try {
      setLoading(true);
      const res = await forgot(email);
      if (!res) {
        return;
      }
      setSentMail(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='forgot-password-page'>
      <Loading loading={loading} />
      <Row justify='center' className='breadcrumb-header com-bg-gray'>
        <Col xs={24} md={23} lg={21}>
          <Breadcrumb separator={<ChevronRight fill='#D8D6D6' />}>
            <Breadcrumb.Item>
              <Link to='/home' className='pro-title-name'>
                Trang chủ
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <div className='pro-title-name'>Quên mật khẩu</div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row justify='center' className='content'>
        <Col xs={24} md={23} lg={21}>
          <div className='reg-title'>Quên mật khẩu</div>
          <Row className='forgot-form' justify='center'>
            {sentMail ? (
              'Đã gửi mail thành công, vui lòng kiểm tra hộp thư của bạn!'
            ) : (
              <>
                <Col className='title' xs={24} md={23} lg={21}>
                  Vui lòng nhập email để đặt lại mật khẩu của bạn
                </Col>
                <Col xs={24} md={23} lg={21}>
                  <Form name='forgot-form' onFinish={handleSendMail}>
                    <Form.Item
                      className='form-input'
                      name='email'
                      rules={[
                        {
                          required: true,
                          message: 'Vui lòng nhập email!',
                        },
                        {
                          type: 'email',
                          message: 'Email không chính xác!',
                        },
                      ]}
                    >
                      <Input name='email' placeholder='Email' />
                    </Form.Item>
                    <Form.Item>
                      <Button className='btn-submit' type='primary' htmlType='submit'>
                        Gửi mail
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  state => ({
    customer: state.user.customer,
  }),
  dispatch => ({
    forgot: email => dispatch(forgotPassword(email)),
  })
)(ForgotPassword);
